.imgInt{
    height:575px;
    display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 100%;
}
@media (max-width:500px) {
    .imgInt{
        width: 100%;
        height:250px;
        display: block;
      margin-left: auto;
      margin-right: auto;
      /* width: 50%; */
    }
}