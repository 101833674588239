.background_container {
    position: absolute;
    z-index: -1;
}
.img_background {
    width: 270px;
    height: 300px;
    background-image: url("../images/pattern-bg.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    position: relative;
    margin-bottom: 5px;
}
@media (min-width: 768px) {
    .img_background {
        width: 330px;
        height: 500px;
        margin-bottom: 10px;
   }
}
@media (min-width: 1024px) {
    .img_background {
        width: 450px;
        height: 500px;
        position: absolute;
        top: 10px;
        left: 20px;
   }
}
