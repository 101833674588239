/* Add a background gradient to the container */
.Container {
  /* background: linear-gradient(135deg, #ff6b6b, #ffa384); */
  padding: 20px;
  border-radius: 10px;
  color: #333;
}

/* Center the image and add a border radius for a smoother look */
.imgCont {
  /* display: block; */
  margin: 10px auto;
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(234, 19, 19, 0.205);
  text-align: center;
}

/* Style the "Buy Now" button */
.btn {
  display: inline-block;
  background-color: #5bc0de;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #39a0c1;
}

/* Add some spacing and style for the course details section */
.con {

  background-color: #a1ffee;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
}

.con .Typography_gutterBottom {
  margin: 10px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #e60000;
}

/* Style the outcome section */
ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
  color: #555;
}

/* Style the course content section */
hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 30px 0;
}

.Typography_variantH6 {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 10px;
  color: #444;
}

/* Style the link at the end */
p a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

p a:hover {
  text-decoration: underline;
}

/* Center the headers */
.Typography_gutterBottom,
.Typography_variantH6 {
  /* text-align: center; */
}

.Typography_variantH6 {
  color: green;
}

.Typography_gutterBottom {
  color: red;
}

/* Add some styles for the outcome and course content headers */
.Typography_variantH4 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 20px 0;
  color: #333;
}

.Typography_variantH4::after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  background-color: #007bff;
  margin: 10px auto;
}

/* Adjust font sizes and margins for better readability */
.Typography_gutterBottom,
.Typography_variantH4 {
  font-size: 1.2rem;
  margin: 10px 0;
}

/* Style the "Buy Now" link */
.NavLink {
  text-align: center;
  min-height: 90%;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the outcome and course content items */
li {
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: #555;
}

/* Add a subtle box shadow to the container */
.Container {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Add some spacing to the outcome and course content sections */
.Container>ul {
  margin-top: 20px;
}

/* Add a border and padding to the course content sections */
.Container>div {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Add some spacing to the course content headers */
.Container>div>h5 {
  margin-top: 10px;
}