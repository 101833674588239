.body-summ{
    background-image:url("../RMDS-bg.jpg");
    background-size:cover;
    background-repeat:no-repeat;
    width:100%;
    height: 100vh;
    padding: 30px;
    /* display: flex; */
    /* justify-content: space-between; */
    /* margin:0 5%;    */
       
}
.logo{
    height: 65px;
    margin: 0 20px;
}
.btn-pay{    
    -webkit-border-radius: 9;
    -moz-border-radius: 9;
    border-radius: 9px;
    font-family: Georgia;
    color: #d60745;
    font-size: 20px;
    background: hsl(43.42deg 100% 55.29%);
    padding: 5px 20px 5px 20px;
    text-decoration: none;  
    border: none;    
    margin:10px;
}
.btn-pay:hover{    
    background: hsl(41.42deg 100% 55.29%);
    text-decoration: none;
      
}
.body-reg{
    background-color:hsl(60deg 44.44% 96.47%);
    background-size:cover;
    background-repeat:no-repeat;
    width:100%;    
    padding: 30px;
}
.body-sch{
    background-image:url("../light-bg.png");
    background-size:cover;
    background-repeat:no-repeat;
    width:100%;    
    padding: 30px;
    display:flex;
    justify-content: space-around; 
    color:hsl(240, 30%, 17%);  
}
.body-speak{
    background-image:url("../light-bg.png");
    background-size:cover;
    background-repeat:no-repeat;
    width:100%;    
    padding: 30px;
    display:flex;
    justify-content: space-around;   
    color:hsl(240, 30%, 17%);

  
}
.speaker-img{
    width: 30rem;
    display: block;   
    margin: 10px;
}
.page-content{
    width:40%;
    height: fit-content;
    float: left;
    margin-top: 10vh;
}
.page-cont-2{
    display: flex;
    justify-content: space-around;
    background-image:url("../light-bg.png");
    background-size:cover;
    background-repeat:no-repeat;
    margin-top: -1vh;
}
.page-cont-3{
    width: 50%;
    margin:0 20px;
}
.details{
    background-color:hsl(43, 92%, 61%); 
    padding: 20px;
    width: 50%;
    float: right;
    text-align:left;
    border-radius: 20px;
    color:hsl(240, 30%, 17%);
    margin: 30px;
}
.right{
    display: flex;
    text-align: left;
    width: 30%;
    justify-content: space-around;
}
.header-summ{
    font-family: 'Arial';
    font-size: 35px;
    display: grid;
    place-items: center;
    color: red;
    /* hsl(240deg 20.22% 34.9%); */
    font-weight: 700;
    background-image:url("../light-bg.png");
    
}

.btn-rmds {
  background: hsl(43.42deg 100% 55.29%)!important;
 
  -webkit-border-radius: 8;
  -moz-border-radius: 8;
  border-radius: 8px;
  font-family: Roboto;
  color: #000000;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  margin: 0 0 0 20px;
  border: hsl(43.42deg 100% 55.29%);
}
.btn-rmds-2{
  background: transparent!important;
 
  -webkit-border-radius: 8;
  -moz-border-radius: 8;
  border-radius: 8px;
  font-family: Roboto;
  color: #000000;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  margin: 0 0 0 20px;
  border:hsl(43.42deg 100% 55.29%) solid 2px;
}

.btn-rmds:hover {
  background: #e80c0c;
  text-decoration: none;
}

.page-img{
    margin-top: -10px;
    width: 500px;
    height: 400px;
}
.subscript{
    font-family: 'Arial'; 
    color:hsl(240, 23%, 29%);    
    text-align: justify;
    /* float:center;     */
}
.sub-header{
    font-family: 'Arial';
    color: red;        
}

.cont{    
    width: 100%;
    font-family: 'Source Serif Pro', serif; 
    color: red;
    font-size: 25px;
    display: grid;
    place-items: center;    
}
.program-box{
    background-color: white;  
    font-family: 'Source Serif Pro', serif; 
    border: 1px solid;   
    font-size: 18px; 
    display: grid;    
    place-items: center;
    width: 70%;
    margin:20px auto;
    padding: 10px;
    /* box-shadow: 5px 5px #888888; */
    border-radius: 20px;
}
.reg{    
    background-color:hsl(60deg 44.44% 96.47%);
    padding: 50px;
    border-radius: 20px;
    /* display: flex; */
    margin: 1rem 0;
    /* justify-content: space-between; */
    font-family: 'Roboto', serif; 
}
.table {
    border:1px solid #b3adad;
    border-collapse:collapse;
    padding:5px;
}
table th {
    border:2px solid  #b3adad;
    padding:5px;
    background-color: #ffc01b;
    color: #313030;
}
table td {
    border:2px solid #b3adad;
    text-align:justify;
    padding:5px;
    background: #f9f9f1;
    color: #313030;
    border-radius: 20px;
} 
.reg-inner{
    display: flex;
    margin: 1rem 0;
    justify-content: space-around;
}
/* .th{
   
} */
.reg-img{
    width: 400px;
    margin-top: -50px;
}
.videoDiv{
    display:flex;
    justify-content:space-around;
    margin:15px;
    /* width:100%; */
}
.posterDiv{
    display:flex;
    justify-content:space-around;
    margin:15px;
}
.pos{
    width: 370px;
    height: 560px;
}


@media (max-width:600px) {
    .videoDiv{
        display:block;
        /* justify-content:space-around;
        margin:15px;
        width:100%; */
        /* margin:0 auto 0 auto;  */
        margin-left:8%;
    }
    .pos{
        width: 300px;
        height: 560px;
        margin-bottom: 15px;
    }
    .posterDiv{
        display:block;
        /* justify-content:space-around; */
        margin:2rem;
    }
    .program-box{
        width: 100%;
    }
    .body-sch{
        display: block;
    }
    .page-cont-3{
        width: 100%;
        margin:0
    }
    .body-speak{
        display: block;
    }
    .speaker-img{
        width: 90%;
        margin:0 5%;
        /* left:0; */
    }
    .reg{
        padding: 0px;
        display: block;
    }
    .reg-inner{
        display: block;
    }
    .page-cont-2{
        display: block;
        margin-top: -20rem;
        height: 100vh;
    }
    .details{
        width: 95%;
        margin: 20px 2.5%;
        
    }
    .page-img{
        width: 90%;
        height: 30%;
        margin:5% auto;
    }
    .page-content{
        width: 100%;
        margin-top: 0;
    }
    .reg-img{
        /* visibility: hidden; */
        width: 90%;
        margin:0 5%;
    }
    .linkanc{
        white-space: pre-wrap!important;
            word-wrap: break-word!important;
            word-break: break-all!important;
            white-space: normal!important;
            display:block!important;
    }
    .right{
        display: flex;        
        width: 100%;
        justify-content: space-around;
        margin:20px auto;
    }
    /* .body-summ{
        display: block;
    
    } */
}