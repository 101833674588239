.testimonials {
    width: 99%;
    height: 700px;
    margin: 0 auto;    
    /* background-color: rgb(208, 204, 204); */
}  
 
.tcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 10px 20px 10px;
    gap: 20px;
    /* animation-duration: 3s;
    animation-name: slidein; */
}
/* @keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
      }
    
    to {
        margin-left: 0%;
        width: 100%;
    }
  } */


/* CSS */
.button-63 {
  align-items: center;
  background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 18px!important;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 13px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  margin: 0 auto;
}

.button-63:active,
.button-63:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-63 {
    font-size: 24px;
    min-width: 196px;
  }
}
.timg_container {
    display: flex;
    flex-direction: column;
    margin: 0 0 40px 0 !important;
}
.tinfo_container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    padding: 15px;
    background-color: white;
    margin-top: -30px!important;
    background-color: 	#F5F5F5;
    text-align: justify !important;
    border: 2px solid black; 
    border-radius: 15px;
    box-shadow: 5px 8px #888888;
    min-height: 400px!important;
}
.timg {
    width: 65%;
    border-radius: 10px;
    box-shadow: 0px 5px 5px #000;
    background-image: "./pattern-bg.svg";
}
.portrait {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.tnav_button {
    position: absolute;
    bottom: -20px;
    background-color: transparent;
}
/* p {
    margin: 60px 10px 10px 10px;
    text-align:justify;
    font-size: 15px;
    color: #202046;
} */
.personal_data {
    /* display: flex;
    flex-direction: column; */
    margin-top: 10px;
}
.name {
    color: #202046;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
}
.profession {
    margin-top: 1px;
    color: #babacf;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
}
.bio{
    margin-top: -10px;
    padding: 20px;    
}

.explore{
    width: 80%;
    margin: 0 auto;
    border: 2px solid black;
}

@media (min-width: 768px) {
    /* .testimonials{
        height: 900px!important;
    } */
    .timg_container {
        width: 50%;
   }
    .tinfo_container {
        width: 50%;
        
   }
    /* p {
        font-size: 18px;
   } */
}
@media (max-width:500px) {
    .testimonials{
        height: 930px;        
    }
    
}
@media (min-width: 1024px) {    
    .tcontainer {
        flex-direction: row-reverse;
        position: relative;
        justify-content: space-between;        
        margin: 60px 10px 20px 10px;  
             
   }
    .timg_container {
        
        width: 50%;
   }
    .tinfo_container {
        width: 45%;
        position: absolute;
        left: 135px;
        margin-top: 60px;
                
   }
   .bio{
    font-size: 20px;
    padding: 10px;
   }
    .nav_button {
        left: 100px;
   }
    .personal_data {
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-top: 40px;
   }
    /* p {
        text-align: left;
        margin: 0;
        font-size: 24px;
   } */
    .name {
        font-size: 22px;
   }
    .profession {
        font-size: 22px;
   }
}
@media (min-width: 1440px) {
    .tinfo_container {
        left: 220px;
        width: 40%;
   }
    .nav_button {
        left: 200px;
   }
    /* p {
        font-size: 23px;
   } */
    .name {
        font-size: 25px;
   }
    .profession {
        font-size: 23px;
   }
}
