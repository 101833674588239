.imgCon{
    /* width: 500px; */
    height:300px;
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.imgCont{
  display:flex;
  justify-content:space-between;

}
@media (max-width:500px) {
  .imgCont{
    display:block;
    /* justify-content:space-between;         */
}
    .imgCon{
        width: 100%;
        height:250px;
        display: block;
      margin-left: auto;
      margin-right: auto;
      /* width: 50%; */
    }
    
}