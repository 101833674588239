.tbutton {
    background-color: white;
    width: 70px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    box-shadow: 0px 4px 3px #000;
}
.arrow {
    cursor: pointer;
}
