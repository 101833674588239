.mentor-cont{
    display: grid;
    place-items: center;
}
.memberContainer{
    font-family: 'Source Serif Pro', serif; 
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;    
}
.mentor-desc{
    font-family: 'Source Serif Pro', serif; 
    font-size: 20px;
    width: 95%;
    display: grid;
    place-items: center;   
    text-align: justify;
}
.memberWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 260px;
    box-shadow: 0 0 20px #00000029;
    align-items: flex-start;
    border-radius: 5px;
    padding: 1rem;
    width: 30%;
    margin: 1rem;
    color: #fff;
    text-align: left;
    background-color: #dc3545;
   
}
.memberName {
    font-weight: bold;
    font-family: Montserrat;
}