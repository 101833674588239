.item{
    width: 100%;    
    text-align: center;
    margin: 0 auto 0 auto;
}
.main{
    display: flexbox;
    text-align: justify!important;
}

.item_details{
    /* background-color: aqua; */
    width: 100%;    
    /* text-align: center; */
    margin: 0 auto 0 auto;
}
.detail{
    /* background-color: aqua; */
    /* display: flexbox; */
    text-align: justify!important;
}
.imgres{
    width: 500px;
    margin: 10px 10px 30px 10px;
    
}
.imgresSum{
    width: 500px;    
    display: block;
    margin: 10px auto 20px auto;
    
}
.btn {
    background-color: DodgerBlue;
    border: none;
    color: white;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 20px;
  }
.btn:hover {
    background-color: RoyalBlue;
  }
.vidresSum{
    width: 600px;
    height: 300px;    
    display: block;
    margin: 10px auto 20px auto;
}
.imgCont{
    padding: 5px;
    display:flex;
    justify-content:space-between;

}
.accordion-button:not(.show){
    color: #ffffff!important  ;
    background-color: rgb(252, 12, 12)!important   ;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%)!important  ;
}
.accordion-button:not(.collapsed) {
    color: #ffffff!important  ;
    background-color: black!important   ;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%)!important  ;
}
.category-card {
    height: 100%;
    background-color: aquamarine;
  }
  
  .category-card .card-img-top {
    height: 200px; /* Adjust the height as per your requirement */
    object-fit: cover;
    background-color: aquamarine;
  }
  
  .category-card .card-body {
    /* height: 70%; Subtract the height of the image */
    width: 100%;
    
  }

  .link_f{
    list-style: none;
    color: black;
  }
@media (max-width:500px) {
    
    .mob{
        margin-top: 50px;
    }
    .imgCont{
        display:block;
        /* justify-content:space-between;         */
    }
    .imgres{        
        height: 200px;
        width: auto;    
        margin: auto;        
    }
    .imgresSum{
        width: 100%;    
        height: 200px;
        display: block;
        margin: 10px auto 20px auto;
        
    }
    .vidresSum{
       width: auto; 
    }
}