.course-container{
    display:flex;
    justify-content:space-around;
}
@media (max-width:500px) {
    .course-container{
        display:block;
    }
    .mob{
        margin-top: 50px;
    }
}