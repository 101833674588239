.navCent{
margin-left: auto;
margin-right: auto;

}
.NavCol{
  background-color: white!important;
  /* box-shadow: 10px; */
  
}
@media (max-width:500px){
  .navCent{
    margin-left: 0;
    }
}